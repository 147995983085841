/* Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px 10px 40px;
  background-color: #d5d5d5; /* You can change this to any color you like */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: #000 1px solid;
}

.header__contact-title {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #000;
  margin: 0;
  padding: 0;
}

.header__logo-img {
  height: 120px;
  width: auto;
  border-radius: 50%;
  margin: 0;
}

.header__nav {
  display: flex;
}

.header__nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding-right: 30px;
  padding-left: 0;
}

.header__nav-item {
  margin-left: 30px;
  font-size: 22px;
  font-family: "Oswald", sans-serif;
}

.header__nav-item a {
  text-decoration: none;
  color: #000; /* Change this color to match your design */
  font-weight: 700; /* Adjust font weight as needed */
  transition: color 0.3s;
}

.header__nav-item a:hover {
  color: #00158e; /* Change this to a hover color of your choice */
}

.header__contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.header__contact-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.header__contact-line-1 {
  display: flex;
  font-family: "Oswald", sans-serif;
  color: black;
  font-weight: 700;
  margin: 0;
  padding: 0 0 5px 0;
  font-size: 24px;
}

.header__contact-line-2 {
  /* Phone number */
  display: flex;
  align-items: center;
  font-family: "Oswald", sans-serif;
  color: #cf0000;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  order: 2;
  justify-content: flex-start;
}

.header__contact-line-3 {
  /* Facebook link */
  display: flex;
  align-items: center;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #00158e;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: none;
  order: 1;
  justify-content: flex-start;
}

.header__contact-line-3:hover {
  cursor: pointer;
  opacity: 60%;
}

.header__contact-icon {
  font-size: 24px;
  height: 28px;
  width: 18px;
  margin-right: 5px;
}

.header__facebook-icon {
  font-size: 24px;
  height: 28px;
  width: 20px;
  margin-right: 5px;
}

.header__appointment-button {
  background: linear-gradient(#ce0000, #00158e);
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Oswald", sans-serif;
  transition: opacity 0.3s;
  margin-left: 1rem;
}

.header__appointment-button:hover {
  opacity: 0.9;
}

.header__schedule-button {
  /* Schedule button */
  background: linear-gradient(135deg, #ce0000, #00158e);
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Oswald", sans-serif;
  transition: transform 0.2s, box-shadow 0.2s;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  order: 3;
  min-width: 200px;
  align-self: flex-start;
}

.header__schedule-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.header__schedule-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Media Queries for Mobile Interface */
@media (max-width: 800px) {
  .header {
    flex-direction: column;
    padding: 10px 20px;
  }

  .header__logo-img {
    height: 80px;
    margin-bottom: 10px;
  }

  .header__nav {
    flex-direction: column;
    align-items: center;
  }

  .header__nav-list {
    flex-direction: column;
    padding-right: 0;
  }

  .header__nav-item {
    margin-left: 0;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .header__contact-info {
    align-items: center;
    width: 100%;
  }

  .header__contact-column {
    align-items: center;
    width: 100%;
    max-width: 300px; /* Limit width for better appearance */
  }

  .header__contact-line-2,
  .header__contact-line-3 {
    justify-content: center;
    width: 100%;
  }

  .header__schedule-button {
    align-self: center;
    width: 100%;
  }

  .header__appointment-button {
    margin: 1rem 0;
  }
}

@media (max-width: 480px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
  }

  .header__logo-img {
    margin-top: 10px;
    height: 90px;
    width: 90px;
  }

  .header__nav-item {
    font-size: 16px;
    padding: 0 14px 0 14px;
    font-weight: 800;
    font-family: "Oswald", sans-serif;
  }

  .header__contact-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header__nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 10px;
  }

  .header__contact-column {
    align-items: center;
    width: 100%;
    max-width: 280px;
  }

  .header__contact-line-1 {
    display: flex;
    font-size: 18px;
    color: #cf0000;
  }

  .header__contact-line-2,
  .header__contact-line-3 {
    justify-content: center;
    width: 100%;
  }

  .header__schedule-button {
    align-self: center;
    width: 100%;
  }
}
